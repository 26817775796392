<template>
    <el-dialog
            title="仓库详情"
            :visible.sync="dialogVisible"
            width="1200px"
            :close-on-click-modal="false"
            custom-class="my-box-34dj"
            center
    >
        <el-descriptions border size="small">
            <template #title>
                <div style="display: flex;align-items: center">
                    <img style="height: 16px;margin-right: 3px" :src="item.flag" alt="">
                    {{ item.title }}
                </div>
            </template>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-box-34dj" :label="des.title"
                                  :span="des.span" v-for="(des,index) in item.des" :key="index">
                <div v-if="des.content">
                    {{ des.content }}
                </div>
                <div v-else>
                    <el-tag effect="dark" size="small" style="margin: 2px" v-for="(tag,index) in des.tags" :key="index">
                        {{ tag }}
                    </el-tag>
                </div>
            </el-descriptions-item>
        </el-descriptions>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" style="width: 300px" @click="save" size="small">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
	name: "desBox",
	data() {
		return {
			dialogVisible: false,
			item: {
				des: []
			}
		}
	},
	methods: {
		save() {
			this.confirm()
		},
		close() {
			this.confirm()
		}
	}
}
</script>
<style lang="less">
.my-box-34dj {
  background: rgb(221, 240, 250);
  border: 1px solid rgb(251, 250, 250) !important;
}

.my-label {
  background: rgba(191, 230, 250, 0.8) !important;
  border: 1px solid rgb(251, 250, 250) !important;
}
</style>

