import Vue from 'vue'

export function createModel(view, options = {}) {

  let componentConstructor = Vue.extend(view)
  componentConstructor.prototype.remove = function() {
    // 先将组件隐藏
    // 延迟300毫秒，等待loading关闭动画执行完之后销毁组件
    setTimeout(() => {

      // 移除挂载的dom元素
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      // 调用组件的$destroy方法进行组件销毁
      this.$destroy()
      componentConstructor = null
    }, 300)
  }
  // return function(){
  return new Promise((resolve, reject) => {
    // 如果组件已渲染，则返回即可
    // 要挂载的元素
    const body = document.body
    // 组件属性
    const opts = {
      ...options
    }
    // 通过构造函数初始化组件 相当于 new Vue()
    const instance = new componentConstructor({
      el: document.createElement('div'),
      data: opts
    })

    instance.confirm = function(res) {
      resolve(res)
      instance.dialogVisible = false
      this.remove()

    }
    instance.cancel = function(res) {
      reject(res)
      instance.dialogVisible = false
      this.remove()

    }
    // 将loading元素挂在到parent上面
    body.appendChild(instance.$el)
    // 显示loading
    instance.$nextTick(() => {
      instance.dialogVisible = true
    })
  })
}

/**
 * 创建Vue自定义属性
 * @param {String} name
 * @param {Object} view
 * @param {Vue} Vue
 */
export function createProperty(name, view, Vue = Vue) {
  Object.defineProperty(Vue.prototype, name, {
    value: (options = {}) => {
      return createModel(view, options, Vue)
    }
  })
}
