<template>
    <div class="box">

        <div v-for="(item,index) in stores" :key="index" @click="openDes(item)">

            <div class="img-box">
                <div>
                    <el-image style="height: 180px;width: 300px" :src="item.flag"></el-image>
                </div>
                <div style="padding: 10px;font-size: 18px">
                    {{ item.title }}
                </div>
            </div>


        </div>

    </div>
</template>

<script>


import {stores} from "@/views/overseas-warehouse/methods/data";
import {createModel} from "@/views/data-report/create-model";
import DesBox from "@/views/overseas-warehouse/desBox";

export default {
	name: "dataA",
	components: {},
	props: {
		box_height: {
			type: String,
			default() {
				return '110px';
			}
		}
	},
	data() {
		return {
			stores
		}
	},
	methods: {
		openDes(item) {
			createModel(DesBox, {item})
		}
	}
}
</script>

<style scoped lang="less">
.box {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: 24%;
  }

  .img-box {
    margin: 0 auto;
    width: 330PX;
    height: 250PX;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;

    box-shadow: 0px 0px 4px 1px rgba(0, 255, 255, 0.8);
    background: linear-gradient(45deg, rgba(0, 255, 255, .1) 0%, rgba(0, 0, 0, .5) 50%, rgba(0, 255, 255, .3) 100%);

    & > div {
      width: 280px;
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

  }
}
</style>
