import us from "@/views/overseas-warehouse/img/us.svg";
import ca from "@/views/overseas-warehouse/img/ca.svg";
import uk from "@/views/overseas-warehouse/img/uk.svg";
import de from "@/views/overseas-warehouse/img/de.svg";
import fr from "@/views/overseas-warehouse/img/fr.svg";
import es from "@/views/overseas-warehouse/img/es.svg";
import au from "@/views/overseas-warehouse/img/au.svg";


export const OverLinks = [
	{title: '海外仓分布', link: '/overseas-warehouse/country', icon: 'el-icon-orange'},
	{title: '监控系统', link: '/overseas-warehouse/sys', icon: 'el-icon-bell'},
	{title: '头程指令', link: '/overseas-warehouse/first', icon: 'el-icon-edit'},
	{title: '自助入库', link: '/overseas-warehouse/wearhouse', icon: 'el-icon-shopping-cart-2'},
	{title: '出库指令', link: '/overseas-warehouse/out', icon: 'el-icon-shopping-cart-1'},

]
export const stores = [
	{
		title: '美国洛杉矶美东仓',
		flag: us,
		des: [
			{
				title: '地理位置',
				content: '美国大约70%的人口都居住在此仓为中心的2到5区内，FBA超过75%的仓库也都设置在以此仓为中心的2到5区之内',span:3,
			},
			{
				title: '特点',span:3,
				content: '可以4天之内可以到达99.98%的美国人口。平均送达时间只有2.26天，平均运费最低，对于中小卖家来说仅一个海外仓就可以解决美国当地快捷服务。',
			},
			{title: '仓库面积', content: '2000平方米',span:1,},
			{title: '支持大货', content: '支持',span:1,},
			{title: '订单处理量', content: '1500',span:1,},
			{title: '派送渠道', content: '', tags: ['DHL', 'UPS', 'USPS', 'FedEx'],span:1,},
			{
				title: '服务内容',
				content: '',span:2,
				tags: ['退货换包装', '更换包装', '退货换标', '退货处理', 'FBA中转']
			},
		]

	},
	{
		title: '美国芝加哥美西仓',
		flag: us,
		des: [
			{
				title: '地理位置',span:3,
				content: '美西仓是美东仓的一个补充，海运12天，空运1天，头程时效最快，成本最低!',
			},
			{
				title: '特点',span:3,
				content: '专注FBA退货贴标，退货转运，美国海外仓批量中转、美国海外仓一件代发（代发货）等；退货操作整理有序，快捷，500克以内产品全美一口价加上头程便宜，总体运费成本处于最低水平。',
			},
			{
				title: '技术优势',span:3,
				content: '海外仓订单管理系统，可通过技术将客户提交的订单推送到距离派送地址最近的仓库进行分派,成本低，时效快，客户体验好。',
			},
			{title: '仓库面积', content: '1000平方米',span:1,},
			{title: '支持大货', content: '支持',span:1,},
			{title: '订单处理量', content: '1000',span:1,},
			{title: '派送渠道', content: '', tags: ['DHL', 'UPS', 'USPS', 'FedEx'],span:1,},
			{title: '服务内容', content: '', tags: ['退货换包装', '更换包装', '退货换标', '退货处理', 'FBA中转'],span:2,},
		]
	},
	{
		title: '约克市美国南部集散中心海外仓',
		flag: us,
		des: [

			{
				title: '特点',span:3,
				content: '约克市集散中心海外仓长期为中国企业合作，为中国企业在美国开辟了稳定的市场与良好的信誉，也为广大eBay，亚马逊，速卖通、阿里快递以及垂直B2C等卖家提供优质头程+仓储+订单处理+本地运输一站式服务。',
			},
			{
				title: '地理位置',span:1,
				content: '约克市',
			},
			{
				title: '技术优势',span:2,
				content: '约克市集散中心海外仓拥有完整的仓储管理API系统，具备设计研发与升级改造能力。',
			},
			{title: '仓库面积', content: '8000平方米',span:1,},
			{title: '支持大货', content: '仓库支持大货',span:1,},
			{title: '订单处理量', content: '3000',span:1,},
			{title: '派送渠道', content: '',span:1, tags: ['DHL', 'UPS', 'USPS', 'FedEx']},
			{
				title: '服务内容',
				content: '',span:3,
				tags: ['一件代发', '退货换包装', '新品更换包装', '退货换标', '退货回国', 'FBA中转', '退货检测清关服务', '虚拟海外仓', '退货处理']
			},
		]
	},
	{
		title: '加拿大卡尔加里海外仓',
		flag: ca,
		des: [
			{
				title: '地理位置',span:3,
				content: '加拿大有一个海外仓坐落于卡尔加里，服务半径覆盖加拿大全境',
			},
			{title: '仓库面积', content: '3000平方米',span:1,},
			{title: '订单处理量', content: '1000',span:1,},
			{title: '派送渠道', content: '',span:1, tags: ['DHL', 'UPS', 'EMS', '当地邮政', '快递']},
			{
				title: '服务内容',
				content: '',span:2,
				tags: ['清关服务', '分销', '海外售后维修', '海外税法咨询', '退货处理']
			},
		]
	},
	{
		title: '英国伦敦海外仓',
		flag: uk,
		des: [
			{
				title: '特点',span:3,
				content: '提供各类清关、海外仓储、门点配送等一站式全程物流服务。提供进出口代理、进出口报关、报检及相关商务咨询等服务。',
			},
			{title: '仓库面积', content: '3000平方米',span:1,},
			{title: '支持大货', content: '仓库支持大货',span:1,},
			{title: '订单处理量', content: '2000',span:1,},
			{title: '派送渠道', content: '',span:1, tags: ['DHL', 'Royalmail B2C Europe', '当地邮政', '快递']},
			{
				title: '服务内容',
				content: '',span:3,
				tags: ['FBA中转', '分货贴标', '海外客服', '出口退税', '清关服务中转服务', '退货回国', '一件代发']
			},
		]
	},
	{
		title: '英国朴茨茅斯海外仓',
		flag: uk,
		des: [
			{title: '特点', content: '提供FBA中转仓服务，亚马逊退换货服务，订单派送',span:1,},
			{title: '支持派送范围', content: '欧洲境内和欧盟国家',span:1,},
			{title: '支持渠道', content: '',span:1, tags: ['英德西邮政', 'DPD', 'DHL', 'GLS']},
			{title: '派送时效', content: '英德西境内1-3工作日,欧盟2-5工作日',span:1,},
			{title: '仓库面积', content: '2000平方米',span:1,},
			{title: '订单处理量', content: '2000',span:1,},
			{title: '派送渠道', content: '',span:1, tags: ['DHL', 'UPS', 'USPS', 'FedEx']},
			{
				title: '服务内容',
				content: '',span:2,
				tags: ['FBA中转', '退货换包装', '更换包装', '退货回国', '退货换标', '退货处理']
			},
		]
	},
	{
		title: '英国伯明翰海外仓',
		flag: uk,
		des: [
			{
				title: '特点',span:3,
				content: '专注本地海外仓，服务周到，诚信经营，为跨境电商卖家提供英国仓储，头程快递，FBA头程，FBA退换货换标等物流仓储服务和本地化售前售后服务。',
			},
			{title: '仓库面积', content: '4500平方米',span:1,},
			{title: '订单处理量', content: '8000',span:1,},
			{
				title: '派送渠道',
				content: '',span:2,
				tags: ['UPS', ' Royalmail yodel', 'DPD', 'TNT', '当地卡车运输', 'myHermes']
			},
			{
				title: '服务内容',
				content: '',span:3,
				tags: ['虚拟海外仓清仓处理', '海外售后维修', '海外客服', '清关服务', '分销线下产品展示', '退货检测', '中转服务 (FBA)头程派送', '一件代发退货换标', '商标注册', '退货回国', '更换包装', '退货换包装']
			},
		]
	},
	{
		title: '德国柏林海外仓',
		flag: de,
		des: [
			{
				title: '服务半径',span:1,
				content: '覆盖欧洲全境。',
			},
			{title: '仓库面积', content: '2000平方米',span:1,},
			{title: '订单处理量', content: '3000',span:1,},
			{
				title: '派送渠道',
				content: '',span:1,
				tags: ['UPS', 'DHL']
			},
			{
				title: '服务内容',
				content: '',span:1,
				tags: ['分销', '退货处理', '退货检测', '海外售后维修', '代运营']
			},
		]
	},
	{
		title: '德国汉堡海外仓',
		flag: de,
		des: [
			{
				title: '特点',span:3,
				content: '汉堡海外仓位于德国最大的港口和国际贸易中心，向来是进出口贸易仓储，物流服务的必争之地。 占地1500平方米的汉堡仓，操作产能1000票/日。',
			},
			{title: '仓库面积', content: '1000平方米',span:1,},
			{title: '订单处理量', content: '500',span:1,},
			{
				title: '派送渠道',
				content: '',span:1,
				tags: ['DEpost', 'GLS']
			},
			{
				title: '服务内容',
				content: '',span:1,
				tags: ['代运营', '更换包装', '退货换包装', '分货贴标']
			},
		]
	},
	{
		title: '法国昂代海外仓',
		flag: fr,
		des: [
			{
				title: '特点',span:1,
				content: '服务半径覆盖欧洲全境',
			},
			{title: '仓库面积', content: '1000平方米',span:1,},
			{title: '支持大货', content: '仓库支持大货',span:1,},
			{title: '订单处理量', content: '3000',span:1,},
			{
				title: '派送渠道',
				content: '',span:1,
				tags: ['UPS', '当地邮政']
			}
		]
	},
	{
		title: '西班牙伊伦海外仓',
		flag: es,
		des: [
			{
				title: '特点',span:1,
				content: '服务半径覆盖欧洲全境。',
			},
			{title: '仓库面积', content: '4000平方米',span:1,},
			{title: '支持大货', content: '仓库支持大货',span:1,},
			{title: '订单处理量', content: '3000',span:1,},
			{
				title: '派送渠道',
				content: '',span:1,
				tags: ['EMS', '当地邮政', '快递']
			},
			{
				title: '服务内容',
				content: '',span:3,
				tags: ['虚拟海外仓', '退货换标', '一件代发', '海外售后维修', '退货检测退货处理', '分销', '清关服务', '出口退税']
			},
		]
	},
	{
		title: '澳大利亚墨尔本海外仓',
		flag: au,
		des: [
			{
				title: '特点',span:1,
				content: '服务半径覆盖澳洲全境。',
			},
			{title: '仓库面积', content: '3000平方米',span:1,},
			{title: '支持大货', content: '仓库支持大货',span:1,},
			{title: '订单处理量', content: '2000',span:1,},
			{
				title: '派送渠道',
				content: '',span:1,
				tags: ['DHL', '当地邮政', 'UPS', 'DPD', 'FedEx']
			},
			{
				title: '服务内容',
				content: '',span:3,
				tags: ['退货处理', '退货检测', '中转服务', '退货换标', 'FBA中转', '退货回国', '更换包装', '退货换包装', '尾程渠道', '分货贴标', '可亚马逊自提']
			},
		]
	},
]
